.income {
  width: 100%;
  margin-top: 0;
}

.checkboxItem {
  margin-bottom: 0;
}

.checkbox {
  flex-wrap: nowrap;
}

.modal {
  width: 700px;
}
