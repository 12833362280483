.item {
  margin-left: 40px;
  margin-right: 40px;
  border-bottom: 1px solid #353535;
  display: flex;
  cursor: pointer;

  &.comingSoon {
    cursor: auto;
  }

  &:last-child {
    border-bottom: none;
  }

  .link {
    color: white;
    font-size: 22px;
    font-weight: normal;
    line-height: 56px;
    margin-left: -4px;
    text-decoration: none;
    flex: 1 1 auto;
  }

  .chevronLeft {
    margin-left: -4px;
    margin-right: 16px;
    flex: 0 0 auto;
    width: 16px;
  }

  .chevronRight {
    flex: 0 0 auto;
    width: 16px;
  }

  .comingSoonText {
    font-weight: bold;
    font-size: 10px;
    line-height: 56px;
    color: #6f6f6f;
    text-transform: uppercase;
  }
}
