.rescheduleTour {
  font-weight: 600;
  font-size: 24px;
  padding: 24px 0;
}

.rescheduled {
  color: #111e56;
  padding: 8px 0;
  line-height: 20px;
}

.tour {
  padding-bottom: 24px;
}

.button {
  width: 100%;
}

.buttonContainer {
  width: 220px;
  text-align: center;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  .buttonContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 25px;
    width: 100%;
    background-color: white;
    z-index: 2;
  }
}
