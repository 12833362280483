$border-radii: 30px;

.images {
  height: 320px;
  position: relative;
}

.imagesBackground {
  background: #f5f9ff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 $border-radii;
}

.logo {
  position: absolute;
  top: 22px;
  left: 22px;
}

.content {
  background: #f5f9ff;
}

.contentBackground {
  width: 100%;
  background: #ffffff;
  padding: 0 22px;
  border-radius: 0 $border-radii 0 0;
}

.welcomeHeader {
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  color: #111e56;
  padding: 40px 0 16px 0;
}

.welcomeInstructions,
.welcomeInstructions p {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #111e56;
}

.welcomeInstructions a {
  color: #111e56;
  font-weight: 500;
}

.unlockButton {
  width: 100%;
  height: 50px;
  background: #0075ff;
  border: 1px solid #0075ff;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 27px;
}

.modalContent {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.modalButton {
  background: #0075ff;
  border: 1px solid #0075ff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border-radius: 4px;
  height: 40px;
  width: 205px;
  margin-top: 15px;
}
