.label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
  color: #111e56;
}

.overlay {
  max-width: 80%;
}
