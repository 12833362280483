@use '~@darwin-homes/styles/text';

.container {
  margin: auto;
  width: 14rem;
  text-align: center;

  .text {
    @extend .textXl;
  }

  :global(.ant-btn) {
    margin: 1.5rem 0;
  }

  a {
    text-decoration: underline;
  }
}
