.root {
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 900;
  width: 100vw;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  justify-content: space-between;
}

.brand {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.call a {
  color: black;
}

.logo {
  height: 17px;
}

.title {
  color: #8d97a5;
}

@media only screen and (min-width: 800px) {
  .container {
    height: 74px;
  }

  .title {
    font-size: 20px;
    font-weight: 300;
    line-height: 23px;
    margin-left: 5px;
  }
}
