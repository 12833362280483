.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-image: url('homes.png');
  height: 110px;
}

.page {
  min-height: 600px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5e5e5e;
  text-align: center;
  padding: 25px 100px 100px 100px;
}

.title {
  margin: 30px;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  color: #212529;
}

.info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #5e5e5e;
  margin-bottom: 35px;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  margin-bottom: 100px;
}

.step {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0075ff;
  margin: 5px 0;
}

.button {
  width: 100%;
}

.buttonContainer {
  width: 330px;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .title {
    margin: 0 0 20px 0;
    font-size: 24px;
    line-height: 28px;
  }

  .container {
    padding: 20px;
    align-items: flex-start;
    text-align: start;
  }

  .step {
    font-size: 13px;
  }

  .info {
    margin-bottom: 15px;
    font-size: 13px;
  }

  .buttonContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 25px;
    width: 100%;
    background-color: white;
    z-index: 2;
  }
}
