.fixedContainer {
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 100;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .clickTarget {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #1d1d1d;
    opacity: 0;
  }

  .drawer {
    position: absolute;
    background: #1d1d1d;
    width: 300px;
    height: 100%;
    right: 0;
    display: flex;
    flex-direction: column;

    .close {
      flex: 0 0 auto;
      padding: 10px;
      height: 44px;
      align-self: flex-end;
      margin-top: 18px;
      margin-right: 23px;
      cursor: pointer;
    }

    .spacer {
      height: 65px;
      flex: 0 100 65px;
    }

    .nav {
      flex: 1 1 auto;
      overflow: hidden;
      display: flex;
    }

    .callUs {
      flex: 0 0 auto;
    }
  }
}

.appear {
}

.appearActive {
}

.enter {
  .drawer {
    transform: translate3d(300px, 0, 0);

    .clickTarget {
      opacity: 0;
    }
  }
}

.enterActive {
  .drawer {
    transform: translate3d(0, 0, 0);
    transition: transform 0.5s ease;
  }

  .clickTarget {
    opacity: 0.7;
    transition: opacity 0.5s ease;
  }
}

.enterDone {
  .clickTarget {
    opacity: 0.7;
  }
}

.exit {
  .clickTarget {
    opacity: 0.7;
  }
}

.exitActive {
  .drawer {
    transform: translate3d(300px, 0, 0);
    transition: transform 0.5s ease;
  }

  .clickTarget {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
}

.exitDone {
}
