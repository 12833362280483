.section {
  background-color: #232323;
  padding-top: 59px;
  padding-bottom: 59px;
}

@media only screen and (min-width: 800px) {
  .section {
    padding-top: 73px;
    padding-bottom: 79px;
  }
}
