.container {
  margin-top: 30px;
  color: #ffffff;
}

.container p {
  font-size: 14px;
}

.legal {
  margin-bottom: 14px;
}

:global(.logo) {
  width: 113px;
  height: 25px;
  margin-bottom: 15px;
  margin-right: 20px;
}

.container .content:first-child {
  margin-bottom: 34px;
}

.container p a {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

.equalHousing {
  padding-top: 12px;
  height: 64px;
}

@media only screen and (min-width: 800px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .help {
    width: 160px;
    margin-left: 30px;
  }

  .legal {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    align-items: center;
    margin-bottom: 0;
  }
}
