@use '@darwin-homes/styles/colors';
@use '@darwin-homes/styles/text';

.page {
  margin-bottom: 100px;
}

.container {
  color: #5e5e5e;
  padding-bottom: 12px;
}

.back {
  color: colors.$black;

  &:hover {
    color: colors.$blue-700;
    text-decoration: underline;
  }
}

.propertyInfo {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.progress {
  width: 102%;
  margin: 0 -2px;
}

.info {
  @extend .textXl;
  font-weight: 500;
  color: colors.$gray-900;
  margin: 1rem 0;
}

.additionalInfo {
  @extend .textBase;
  color: #1f2220;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.iconInfo {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;

  > svg {
    margin: 0 0.5rem;
    &:first-of-type {
      margin-left: 0;
    }
  }

  .heroIcon {
    width: 1.25rem;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .propertyInfo {
    padding-top: 15px;
  }

  .ctas {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .propertyInfo {
    display: block;
  }
}
