.hamburger {
  outline: none;
}

.hamburger :global(.hamburger-box) {
  width: 20px;
  height: 18px;
}

.hamburger :global(.hamburger-inner) {
  margin-top: -1px;
}

.hamburger :global(.hamburger-inner),
.hamburger :global(.hamburger-inner):before,
.hamburger :global(.hamburger-inner):after {
  width: 20px;
  height: 2px;
  border-radius: 0;
  background-color: #fff;
}

.hamburger.inverted :global(.hamburger-inner),
.hamburger.inverted :global(.hamburger-inner):before,
.hamburger.inverted :global(.hamburger-inner):after {
  background-color: #000;
}

.hamburger:global(.hamburger.is-active .hamburger-box) {
  transform: translateX(-4px);
}

.hamburger:global(.hamburger.is-active .hamburger-inner),
.hamburger:global(.hamburger.is-active .hamburger-inner):after,
.hamburger:global(.hamburger.is-active .hamburger-inner):before {
  background-color: #fff;
  width: 28px;
}

.hamburger.inverted:global(.hamburger.is-active .hamburger-inner),
.hamburger.inverted:global(.hamburger.is-active .hamburger-inner):after,
.hamburger.inverted:global(.hamburger.is-active .hamburger-inner):before {
  background-color: #000;
}

.hamburger :global(.hamburger-inner):before {
  top: -8px;
}

.hamburger :global(.hamburger-inner):after {
  bottom: -8px;
}
