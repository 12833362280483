.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.parentHeight {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.textOffset {
  padding-bottom: 80px;
}

.text {
  color: #c2c2c2;
  font-size: 32px;
  font-weight: 600;
  padding-top: 80px;
}
