@use '@darwin-homes/styles/colors';
@use '@darwin-homes/styles/text';

.containerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.stepContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 680px;

  h1 {
    @extend .text4Xl;
    color: colors.$gray-900;
    margin: 3rem 0 0.5rem;
  }

  h2 {
    @extend .textBase;
    color: colors.$gray-700;
    margin-bottom: 2rem;
  }

  .buttonContainer {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 2rem;
  }

  .btn {
    border-radius: 0px;
    height: 56px;
    width: 100%;
    white-space: nowrap;
  }

  .btn:hover {
    cursor: pointer;
  }

  .btn:not(:first-child) {
    margin-right: 15px;
  }

  .btn:focus {
    outline: none;
  }

  .primary {
    background: colors.$blue-700;
    color: colors.$white;
  }

  .primary:hover {
    background: colors.$blue-700;
  }

  .secondary {
    color: colors.$blue-700;
    border: 1px solid colors.$blue-700;
    background: colors.$white;
  }

  .secondary:hover {
    color: colors.$blue-700;
    border-color: colors.$blue-700;
  }

  .securityAlert {
    background-color: colors.$red-50;
    border-radius: 8px;

    .securityAlertText {
      line-height: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 24px;
      color: colors.$gray-700;
    }

    .securityAlertHeader {
      color: colors.$red-700;
    }
  }
}

@media only screen and (max-width: 768px) {
  .buttonContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 25px;
    width: 100%;
    background-color: colors.$white;
    z-index: 2;
  }

  .stepContainer {
    h1 {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }

  .containerWrapper {
    padding: 5px;
  }
}
