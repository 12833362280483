@use '@darwin-homes/styles/borders';
@use '@darwin-homes/styles/colors';
@use '@darwin-homes/styles/text';

.item {
  width: 100%;
  margin: 12px 0;
}

.tip {
  @extend .textSm;
  background-color: colors.$gray-50;
  border-radius: borders.$radius-large;
  color: colors.$gray-700;
  padding: 1rem;
  margin-top: 1rem;
  .checkbox {
    flex-wrap: nowrap;
  }
}
