@use '@darwin-homes/styles/colors';
@use '@darwin-homes/styles/text';

.container {
  width: 100%;
}

.errorMessage {
  @extend .textXs;
  color: colors.$red-700;
  margin-top: 0.5rem;
  display: flex;

  > svg {
    vertical-align: top;
    width: 14px;
    height: 14px;
    margin-right: 0.25rem;
  }
}
