.header {
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 10;
  transition: height 0.5s ease;
  background-color: #1d1d1d;
}

.header :global(.hamburger) {
  position: absolute;
  top: 16px;
  right: 15px;
}

.header :global(.hamburger) {
  right: calc(15px + constant(safe-area-inset-right));
}

.header :global(.hamburger) {
  right: calc(15px + env(safe-area-inset-right));
}

.snapped {
  background: #fff;
  transition: background-color 0.5s ease;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  height: 80px;
}

.lightBg .nav .link,
.lightBg .subMenu p {
  color: #4f4f4f;
  text-decoration: none;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-right: 0;
  position: relative;
}

.wrapper {
  padding-right: constant(safe-area-inset-right);
}

.wrapper {
  padding-right: env(safe-area-inset-right);
}

.nav .link,
.subMenu p {
  color: white;
  font-weight: normal;
  text-decoration: none;
}

.subMenu {
  position: relative;
}

.subMenu p {
  margin-bottom: 0;
}

.subMenu p::after {
  content: ' ';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: url(./SubMenu/triangle.svg) no-repeat bottom center;
  margin-left: 9px;
  position: relative;
  top: -2px;
  transition: transform 0.4s;
}

.lightBg .subMenu p::after {
  background-image: url(./SubMenu/triangle-dark.svg);
}

.subMenuItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
}

.callUs {
  font-weight: 500;
  font-size: 12px;
}

.callUs .phoneNumber {
  font-size: 16px;
  padding-left: 12px;
  line-height: 24px;
  vertical-align: bottom;
}

.callUs a {
  color: #fff;
}

.mobileMenu .callUs {
  font-size: 16px;
  margin-top: 10px;
  margin-left: 36px;
  margin-bottom: 57px;
}

.mobileMenu .callUs .phoneNumber {
  font-size: 20px;
}

.lightBg .callUs a {
  color: #4f4f4f;
}

.wrapper > a {
  align-self: flex-start;
  margin-top: 28px;
  min-height: 25px;
}

.wrapper > .logoReplacement {
  align-self: center;
  justify-self: center;
  width: fit-content;
  height: fit-content;
}

.logo {
  width: 113px;
  height: 25px;
}

.miniLogo {
  width: 70px;
  height: 19px;
}

.subMenuItemsWrapper {
  display: none;
}

.subMenu p {
  display: inline-block;
}

.desktop.header {
  display: flex;
  height: 120px;
  background-color: transparent;
  transition: background-color 0.5s ease;
}

.desktop.header.lightBg {
  background: #fff;
}

.desktop.snapped.header {
  height: 80px;
}

.desktop .wrapper {
  flex-direction: row;
  align-items: center;
  padding-right: 40px;
  padding-left: 40px;
}

.desktop .wrapper {
  padding-right: calc(40px + constant(safe-area-inset-right));
  padding-left: calc(40px + constant(safe-area-inset-left));
}

.desktop .wrapper {
  padding-right: calc(40px + env(safe-area-inset-right));
  padding-left: calc(40px + env(safe-area-inset-left));
}

.desktop .nav {
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  justify-content: space-between;
  width: 625px;
  align-items: center;
  margin-left: 10px;
  opacity: 1;
  padding: 0;
  overflow: visible;
}

.desktop .nav .link,
.desktop .subMenu p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
}

.desktop .nav .link,
.desktop .subMenu {
  margin-left: 0;
}

.desktop .subMenu {
  max-height: 24px;
}

.desktop .subMenu p {
  margin-left: 0;
}

.desktop .subMenuItemsWrapper {
  position: absolute;
  padding-top: 21px;
}

.desktop .subMenuItems {
  padding: 25px;
  background: #fff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  min-width: 165px;
  max-width: 100%;
}

.desktop .subMenuItems::before {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  position: absolute;
  top: 16px;
}

.desktop .subMenu:hover .subMenuItemsWrapper {
  display: block;
}

.desktop .subMenu .subMenuItems a,
.desktop .subMenu .subMenuItems .link.comingSoon {
  color: #1d1d1d;
  margin-left: 0;
  margin-bottom: 16px;
}

.desktop .subMenu .subMenuItems a:last-child,
.desktop .subMenu .subMenuItems .link.comingSoon:last-child {
  margin-bottom: 0;
}

.desktop .subMenu .subMenuItems .link.comingSoon {
  border-bottom: 2px solid transparent;
  white-space: nowrap;
}

.desktop .subMenu .subMenuItems .link.comingSoon span {
  opacity: 0.5;
  line-height: 25px;
  font-size: 12px;
}

.desktop .wrapper > a {
  align-self: auto;
  margin-top: 0;
}

.desktop .logo {
  width: 154px;
  height: 34px;
}

.bottomBorder {
  border-bottom: 1px solid #f3f3f3;
}

@media only screen and (min-width: 800px) {
  .header :global(.hamburger) {
    right: 25px;
  }

  .header :global(.hamburger) {
    right: calc(25px + constant(safe-area-inset-right));
  }

  .header :global(.hamburger) {
    right: calc(25px + env(safe-area-inset-right));
  }
}

@media only screen and (min-width: 1280px) {
  .desktop .wrapper {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

  .edgeAlign {
    width: 100% !important;
    max-width: 100%;
  }
}
