@use '@darwin-homes/styles/colors';

.card {
  background-color: colors.$white;
  overflow: hidden;
  border-radius: 0.375rem;
  padding: 0.75rem;
  margin: 0.5rem;
  border: 1px solid colors.$gray-300;
  grid-column: span 2 / span 2;
}

.cardTitle {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: black;
}
.columnContainer {
  display: grid;
}

.column {
  grid-column: span 1 / span 1;
}
.subtitle {
  text-transform: uppercase;
  color: colors.$gray-400;
  margin: 1rem 0.25rem 0.25rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
}

@media only screen and (max-width: 600px) {
  .card {
    grid-column: span 5 / span 5;
  }
  .columnContainer {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 600px) and (min-width: 400px) {
  .card {
    grid-column: span 5 / span 5;
  }
  .columnContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1024px) {
  .columnContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
