@use '@darwin-homes/styles/colors';

.button {
  border-radius: 1.2rem;
  height: 5rem;
  border: 1px solid colors.$gray-300;
  background-color: colors.$white;
  padding: 1rem;

  svg {
    display: inline-block;
    margin: 0 0.25rem;
    vertical-align: bottom;
    width: 2.5rem;
    stroke-width: 0.7px;
    padding-top: 2px;
    border: none;
  }
}

.bad {
  color: colors.$red-800;
  &:hover,
  :active {
    background-color: colors.$red-50;
    border: 1px solid #e57373; // red-300
    cursor: pointer;

    svg {
      border: none;
    }
  }
}

.setBad {
  @extend .bad, :hover;
}

.good {
  color: colors.$green-600;
  &:hover,
  :active {
    background-color: colors.$green-50;
    border: 1px solid #81c784; // green-300
    cursor: pointer;

    svg {
      border: none;
    }
  }
}

.setGood {
  @extend .good, :hover;
}
