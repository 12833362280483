@use '@darwin-homes/styles/borders';
@use '@darwin-homes/styles/colors';

.tip {
  padding: 16px;
  background-color: colors.$blue-50;
  border-radius: borders.$radius-large;
  margin-top: 1rem;
}

.tipTitle {
  color: colors.$blue-700;
  margin-bottom: 1rem;
}

.tipList {
  padding-left: 1.25rem;
  margin-bottom: 0;
}
