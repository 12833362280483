.item {
  width: 100%;
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.radioTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0 20px;
}

.scheduleDayStep > div:first-child {
  width: auto;
}

.requestedTourDate {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  > label:global(.ant-radio-button-wrapper) {
    border-radius: 4px;
    height: auto;
    min-height: 80px;
    text-align: center;

    > span > div {
      &:first-child {
        font-weight: 500;
      }
      &:last-child {
        line-height: 20px;
        padding-bottom: 8px;
      }
    }
  }
}
