.container {
  padding-right: 10px;
  padding-left: 10px;
  max-width: 1280px;
}

/*
 * Both variants of the safe-area-inset syntax must be in separate
 * blocks otherwise when minifying the CSS only the last variant is
 * included into the production CSS
 */
.container {
  padding-right: calc(10px + constant(safe-area-inset-right));
  padding-left: calc(10px + constant(safe-area-inset-left));
}

.container {
  padding-right: calc(10px + env(safe-area-inset-right));
  padding-left: calc(10px + env(safe-area-inset-left));
}

@media only screen and (min-width: 800px) {
  .container {
    padding-right: 40px;
    padding-left: 40px;
  }

  .container {
    padding-right: calc(40px + constant(safe-area-inset-right));
    padding-left: calc(40px + constant(safe-area-inset-left));
  }

  .container {
    padding-right: calc(40px + env(safe-area-inset-right));
    padding-left: calc(40px + env(safe-area-inset-left));
  }
}

@media only screen and (min-width: 1280px) {
  .container {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
}
