@use '@darwin-homes/styles/colors';
@use '~@darwin-homes/styles/text';

.iconHeader {
  text-align: center;
  padding: 3rem 1rem;
  @extend .text2Xl;

  svg {
    display: inline-block;
    height: 4rem;
    margin: 0 0.25rem;
    padding: 0.75rem;
    vertical-align: bottom;
    width: 4rem;
    stroke-width: 0.7px;
    border-radius: 50%;
    color: colors.$blue-600;
    background: colors.$blue-50;
  }

  .title {
    font-weight: text.$bold;
    padding: 1.5rem 0 0.5rem;
  }

  .text {
    color: colors.$blue-600;
    font-weight: text.$light;
  }
}
