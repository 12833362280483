@use '@darwin-homes/styles/colors';
@use '~@darwin-homes/styles/text';

.form {
  background-color: colors.$gray-100;
  text-align: center;
  min-height: 68vh;
  padding: 0 2rem;

  .title {
    font-weight: text.$bold;
    padding: 1.5rem;
    @extend .textXl;
  }

  .buttonBlock {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    width: 13rem;
    margin: auto;
  }
}
.formContainer {
  margin: auto;
  width: 13rem;
  text-align: left;
  padding-bottom: 4rem;

  :global(.ant-checkbox-wrapper) {
    display: block;
    margin-left: 0;
    padding: 4px 0;
  }
  :global(.ant-btn) {
    width: 100%;
  }
}

.noThanks {
  font-weight: text.$semibold;
  text-decoration: underline;
  padding: 1.5rem;
  &:hover {
    cursor: pointer;
  }
}
.text {
  @extend .textXl;
  padding-bottom: 1rem;
}
