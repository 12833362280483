.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5e5e5e;
  min-height: 600px;
  text-align: center;
  padding: 100px 18px 0;
  margin-bottom: 100px;
  max-width: 960px;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  .container {
    padding-top: 24px;
    margin-bottom: 12px;
  }
}
