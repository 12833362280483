.subMenu {
  position: absolute;
  top: 0;
}

.appear {
}

.appearActive {
}

.enter {
  transform: translate3d(300px, 0, 0);
}

.enterActive {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease;
}

.enterDone {
}

.exit {
}

.exitActive {
  transform: translate3d(300px, 0, 0);
  transition: transform 0.5s ease;
}

.exitDone {
}
