.container {
  margin-bottom: 35px;
}

.linkedIn {
  width: 27px;
  height: 27px;
}

.link {
  color: white;
  font-size: 18px;
  border-bottom: 2px solid transparent;
  margin-top: 30px;
}

.comingSoon {
}

.comingSoon span {
  line-height: 23px;
  font-size: 12px;
  color: #767676;
  margin-left: 5px;
}

.link:first-child {
  margin-top: 0;
}

.link:hover {
  color: white;
  border-bottom: 2px solid white;
}

.link.comingSoon:hover {
  border-bottom: 2px solid transparent;
}

.subMenu {
  margin-top: 30px;
}

.subMenu.selected {
  border-bottom: none;
}

.subMenu p {
  font-size: 18px;
  line-height: normal;
  margin-bottom: 0;
  color: #fff;
}

.subMenu .link {
  font-size: 14px;
}

.subMenuItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.subMenuItems a,
.subMenuItems .comingSoon {
  margin-top: 10px;
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selected {
  border-bottom: 2px solid white;
}

@media only screen and (min-width: 800px) {
  .container {
    color: #ffffff;
    margin-bottom: 30px;
  }

  .nav {
    flex-direction: row;
    justify-content: space-between;
  }

  .link {
    margin-top: 0;
  }

  .subMenu {
    margin-top: 0;
    margin-bottom: 0;
  }
}
