@use '@darwin-homes/styles/gridSystem';
@use '@darwin-homes/styles/mediaQueries';
@use '@darwin-homes/styles/colors';

.navWrapper {
  z-index: var(--z-index-nav);
  top: 0;
  background: colors.$white;
  width: 100%;
}

.sticky {
  position: sticky;
}

.nav {
  height: 72px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid colors.$gray-200;
}

.icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.2rem;
}

.navLink {
  margin-right: 21px;
  margin-left: auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  width: fit-content;

  a {
    color: colors.$blue-700;
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
  }

  svg {
    color: colors.$blue-700;
  }
}

.dropDownContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100%;
  position: relative;
  justify-content: space-around;
  border-right: 1px solid colors.$gray-200;

  @include mediaQueries.media-up(md) {
    padding-right: 40px;
    flex-grow: initial;
    justify-content: center;
    min-width: 350px;
  }

  @include mediaQueries.media-up(lg) {
    min-width: 350px;
    padding-right: 21px;
    justify-content: start;
  }

  @include mediaQueries.media-up(xl) {
    padding-right: 40px;
  }

  @include mediaQueries.media-down(lg) {
    flex-grow: 0.25;
    padding: 0 1rem;
  }
}

.logo {
  display: block;
  width: 110px;
  line-height: unset;
  margin-bottom: 0;

  @include mediaQueries.media-up(md) {
    margin: 0;
  }

  @include mediaQueries.media-up(lg) {
    margin-left: 7rem;
  }
}

.navItem {
  margin-right: 21px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  width: fit-content;

  @include mediaQueries.media-up(xl) {
    margin-right: 32px;
  }
  @include mediaQueries.media-down(lg) {
    margin-left: auto;
  }
}

.navDesktop {
  height: 100%;
  margin-left: 40px;
  width: 100%;

  @include mediaQueries.media-down(lg) {
    margin-left: auto;
  }
}
