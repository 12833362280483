@use '@darwin-homes/styles/colors';

.bannerWrapper {
  width: 100%;
  border-bottom: 1px solid colors.$gray-300;
  padding: 4rem 0.5rem 0.75rem;

  svg {
    display: inline-block;
    height: 1.25rem;
    margin: 0 0.25rem;
    padding-bottom: 0.25rem;
    vertical-align: bottom;
    width: 1.25rem;
  }
}

.banner {
  display: flex;
  justify-content: space-between;
}
.address {
  color: colors.$black;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0.25rem;
}
.subBanner {
  display: flex;
  flex-direction: column;
}
.sqFt {
  margin-left: 1rem;
}
.rentBlock {
  display: flex;
  flex-direction: column;
  float: right;
  justify-content: space-between;
  color: colors.$black;
  font-weight: 500;
  font-size: 1.125rem;
  text-align: right;
}
.expandBlock {
  cursor: pointer;
  color: colors.$blue-500;
  font-size: 0.875rem;
  font-weight: 400;
}
.expandedBanner {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.imgWrapper {
  margin: 0.5rem;
  grid-column: span 1 / span 1;

  img {
    border-radius: 0.375rem;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.cameraIcon {
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .subBanner {
    flex-direction: row;
    div:first-child {
      margin-left: 0;
    }
    div {
      margin-left: 1rem;
    }
  }
}
@media only screen and (min-width: 800px) {
  .bannerWrapper {
    padding: 5rem 5rem 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .imgWrapper {
    grid-column: span 5 / span 5;
  }
}
