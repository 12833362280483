@use '@darwin-homes/styles/borders';
@use '@darwin-homes/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 42px;
  font-weight: 500;
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #111e56;
  font-size: 30px;
  margin: 1rem;
  line-height: 36px;
}

.successCopy {
  color: colors.$gray-700;
  line-height: 21px;
  text-align: center;
  font-size: 16px;
}

.tip {
  padding: 16px;
  background-color: colors.$blue-50;
  border-radius: borders.$radius-large;
  margin-top: 2rem;
}

.tipTitle {
  color: colors.$blue-700;
  margin-bottom: 1rem;
}

.tipList {
  padding-left: 1rem;
  margin-bottom: 0;
}

.buttonContainer {
  margin-top: 3rem;
  border-color: #111e56;
  border: 1px solid colors.$gray-300;
  border-radius: 3px;
}

.btn {
  width: 100%;
  color: colors.$gray-500;
}
