@use '@darwin-homes/styles/colors';

.item {
  width: 100%;
  margin: 0;
}

.radioWrapper {
  div {
    width: 100%;
  }

  label {
    justify-content: center;
    align-content: center;
    cursor: pointer;
    border: 1px solid colors.$gray-300;
    height: 56px;
    margin-left: 0;
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:hover {
      border: 1px solid colors.$blue-500;
      color: colors.$blue-500;
    }
  }
}

.selected {
  label {
    border: 1px solid colors.$blue-500;
    color: colors.$blue-500;
  }
}

.radio {
  display: none;
}
