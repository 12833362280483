@use '@darwin-homes/styles/borders';
@use '@darwin-homes/styles/colors';
@use '@darwin-homes/styles/text';

.item {
  width: 100%;
}

.containerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.stepContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 680px;

  h1 {
    @extend .text4Xl;
    color: colors.$gray-900;
    margin: 3rem 0 0.5rem;
  }

  h2 {
    @extend .textBase;
    color: colors.$gray-700;
    margin-bottom: 2rem;
  }

  .buttonContainer {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }

  .btn {
    border-radius: 0px;
    height: 56px;
    width: 100%;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 768px) {
  .buttonContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 25px;
    width: 100%;
    background-color: colors.$white;
    z-index: 2;
  }

  .overlayClass {
    z-index: 10;
  }

  .stepContainer {
    h1 {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .hasAlert {
      margin-top: 1rem;
    }
    margin-bottom: 100px;
  }

  .containerWrapper {
    padding: 5px;
  }
}

.tip {
  padding: 16px;
  background-color: colors.$blue-50;
  border-radius: borders.$radius-large;
  margin-top: 1rem;
}

.tipTitle {
  color: colors.$blue-700;
  margin-bottom: 1rem;
}

.tipList {
  padding-left: 1rem;
  margin-bottom: 0;
}

.alert {
  margin-top: 1rem;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
}
