@import '~antd/dist/antd.less';
@import '@darwin-homes/styles/inter.css';

html,
body {
  line-height: normal;
  font-weight: normal;
  background-color: #ffffff !important;
  font-family: Inter !important;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
}

h2 {
  font-size: 26px;
  font-style: normal;
  font-weight: normal;
}

h3 {
  font-size: 26px;
  font-style: normal;
  font-weight: bold;
}

p {
  font-size: 18px;
  font-weight: normal;
}

@media only screen and (min-width: 800px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 22px;
  }
}

.app {
  /*
   * Ant Design Button customization
   */
  .ant-btn-background-ghost {
    font-weight: bold;
  }

  @media only screen and (max-width: 414px) {
    .ant-btn {
      width: 100%;
    }
  }

  /*
   * Ant Design Modal customization
   */
  &.dw-modal-visible > #root {
    filter: blur(5px) grayscale(0.7);
    transition: filter 0.3s ease, grayscale 0.3s ease;
  }

  .ant-modal {
    max-width: calc(100% - 28px);

    @media only screen and (max-width: 560px) {
      max-width: 95%;
    }
  }
}

@secondary-color: #2D9CDB;@primary-color: #0075FF;@outline-color: @primary-color;@input-hover-border-color: @primary-color;@body-background: #f9f9fb;@font-family: 'Roboto', sans-serif;@font-size-base: 14px;@link-color: @secondary-color;@border-color-base: #E5E5E5;@radio-size: 20px;@checkbox-size: 20px;@checkbox-check-color: @primary-color;@card-head-color: #949494;@card-radius: 6px;@card-padding-base: 32px;@label-color: #626262;@tabs-active-color: #2D4059;@tabs-highlight-color: #2D4059;@tabs-hover-color: #2D4059;@tabs-ink-bar-color: #2D4059;@tabs-title-font-size: 16px;@tabs-title-font-size-lg: 16px;@tabs-title-font-size-sm: 16px;@tabs-bar-margin: 0 0 32px 0;