.notFound {
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1,
  h2 {
    text-align: center;
  }

  h1 {
    span {
      font-size: 4em;
    }
  }

  h2 {
    margin-top: 20px;
  }

  p {
    text-align: center;
    margin-top: 18px;

    a {
      color: #656565;
      text-decoration: underline;
    }
  }
}

@media only screen and (min-width: 800px) {
  .notFound {
    padding: 120px 0px;
    margin-bottom: 40px;
  }
}
