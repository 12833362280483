@use '@darwin-homes/styles/borders';
@use '@darwin-homes/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: colors.$gray-700;
  min-height: 600px;
  padding-top: 74px;
  margin-bottom: 100px;
  width: 100%;
  max-width: 680px;
}

.content {
  text-align: center;

  h1 {
    margin-bottom: 0.25rem;
  }

  .listingInfo {
    margin: 3rem 0;

    .listingItems {
      display: flex;
      justify-content: center;

      .square {
        width: 4px;
        height: 4px;
        background-color: colors.$gray-700;
        margin: 9px 5px 0;
      }
    }
  }
}

.tip {
  padding: 16px;
  background-color: colors.$blue-50;
  border-radius: borders.$radius-large;
  margin-bottom: 2rem;

  .tipTitle {
    color: colors.$blue-700;
  }

  .tipSubTitle {
    margin-top: 1rem;
  }

  .tipList {
    padding-left: 1.25rem;
    margin-bottom: 0;

    a {
      color: colors.$blue-700;
    }
  }
}

@media only screen and (max-width: 800px) {
  .container {
    padding-top: 40px;
  }
}
