.subMenu {
  position: relative;
  background: #1d1d1d;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0 0 auto;
  }

  .items {
    overflow: auto;
    flex: 1 1 auto;
  }
}
